import { Stack } from "react-bootstrap";
import { TeeTypeGolf, TeeTypePAR3 } from "./Config";
import { getFairwayIcon } from "./FairwayIcons";
import { BsFillRecordFill } from "react-icons/bs";

// -----------------------------------------------------------------------

const adminUserId = 'WbLPQXrZRkZyMZoVG6kX9oNfwir2';

export const isAdmin = (userId) => {
    return userId === adminUserId;
}

// -----------------------------------------------------------------------

export const getHoleHCPScoreCummulative = (scoreCard, holeNumber) => {
    return scoreCard.holes.reduce((count, hole) => hole.number <= holeNumber ? count + getHoleHCPScore(hole) : count, 0);
}

export const getHoleScoreCummulative = (scoreCard, holeNumber) => {
    return scoreCard.holes.reduce((count, hole) => hole.number <= holeNumber ? count + getHoleScore(hole) : count, 0);
}

export const getHoleHCPScore = hole => {
    return (hole && hole.shotCount !== 0) ? getHoleHCPShotCount(hole) - (hole.par + hole.extraShots) : 0;
}

export const getHoleScore = hole => {
    return hole?.shotCount !== 0 ? getHoleShotCount(hole) - hole?.par : 0;
}

export const getHoleShotCount = hole => {
    if (isNaN(hole?.shotCount))
        return 0
    return hole?.shotCount ? (hole?.shotCount === -1 ? hole?.par + hole?.extraShots + 2 : hole?.shotCount) : 0;
}

export const getHoleHCPShotCount = hole => {
    return hole.shotCount === -1 ? hole.par + hole.extraShots + 2 : hole.shotCount;
}

// -----------------------------------------------------------------------

export const getScoreCardHCPScore = scoreCard => {
    return scoreCard?.holes?.reduce((prev, hole) => prev + getHoleHCPScore(hole), 0) || 0;
}

export const getScoreCardScore = scoreCard => {
    return scoreCard?.holes?.reduce((prev, hole) => prev + getHoleScore(hole), 0) || 0;
}

export const getScoreCardShotCount = scoreCard => {
    return scoreCard?.holes?.reduce((prev, hole) => prev + getHoleShotCount(hole), 0);
}

export const getScoreCardExtraShots = scoreCard => {
    return scoreCard?.holes?.reduce((prev, hole) => prev + hole.extraShots, 0);
}

export const getScoreCardPar = scoreCard => {
    return scoreCard?.holes?.reduce((prev, hole) => prev + hole.par, 0);
}

export const getScoreCardDistance = scoreCard => {
    return scoreCard?.holes?.reduce((prev, hole) => prev + hole.distance, 0);
}

export const getScoreCardGIRPerCent = scoreCard => {
    if (!scoreCard?.holes) {
        return 0
    }
    const gir = scoreCard?.holes?.reduce((prev, hole) => prev + (hole.gir || false ? 1 : 0), 0);
    return gir / scoreCard.holeCount * 100;
}

export const getScoreCardPutts = scoreCard => {
    const putts = scoreCard?.holes?.reduce((prev, hole) => prev + hole.puttCount, 0);
    return putts;
}

export const getScoreCardStablefordByShowHCP = (scoreCard, showHCP) => {
    return showHCP ? getScoreCardHCPStableford(scoreCard) : getScoreCardStableford(scoreCard);
}

export const getScoreCardStableford = scoreCard => {
    const stableford = scoreCard?.holes?.reduce((prev, hole) => prev + calculateHoleStableford(hole), 0);
    return stableford;
}

export const getScoreCardHCPStableford = scoreCard => {
    const stableford = scoreCard?.holes?.reduce((prev, hole) => prev + calculateHoleHCPStableford(hole), 0);
    return stableford;
}

export const getScoreCardPuttsAverage = scoreCard => {
    const putts = scoreCard?.holes?.reduce((prev, hole) => prev + hole.puttCount, 0);
    return (putts || 0) / (getScoreCardPlayedHoles(scoreCard) || 0.00001);
}

export const getScoreCardFairwayPerCent = scoreCard => {
    const fairway = scoreCard?.holes?.reduce((prev, hole) => prev + (hole.fairway === 1 ? 1 : 0), 0);
    return (fairway || 0) / (getScoreCardPlayedHoles(scoreCard) || 0.00001) * 100;
}

export const getScoreCardDriverFairway = (scoreCard, fairwayId) => {
    const fairway = scoreCard?.holes?.reduce((prev, hole) => prev + (hole.fairway === fairwayId ? 1 : 0), 0);
    return fairway;
}

export const getScoreCardParRange = (scoreCard, start, end) => {
    return scoreCard?.holes?.filter(hole => hole.number >= start && hole.number <= end).reduce((prev, hole) => prev + hole.par, 0);
}

export const getScoreCardOverHCP = (scoreCard) => {
    if (!scoreCard?.holes) {
        return 0
    }
    return scoreCard?.holes?.reduce((prev, hole) => prev + (getHoleHCPScore(hole) > 0 ? 1 : 0), 0);
}

export const getScoreCardPlayedHoles = scoreCard => {
    return scoreCard?.holes?.filter(hole => hole.shotCount !== 0).length || 0;
}

export const getScoreCardByHCPScore = (scoreCard, result) => {
    return scoreCard?.holes.reduce((prev, hole) => {
        return prev + ((getHoleHCPScore(hole) === result && hole.shotCount !== 0) ? 1 : 0);
    }, 0)
}

export const getScoreCardByScore = (scoreCard, result) => {
    return scoreCard?.holes.reduce((prev, hole) => {
        return prev + (getHoleScore(hole) === result && hole.shotCount !== 0 ? 1 : 0);
    }, 0)
}

export const getScoreCardGameIndex = scoreCard => {
    const shots = getScoreCardShotCount(scoreCard);
    //    const handicapFactor = scoreCard.handicap / scoreCard.handicapPlay;
    const par = getScoreCardPar(scoreCard);
    const factor = 72 / par;
    return Math.round(factor * shots);
}

export const scoreCardExtrapolatedShots = (appContext, scoreCard) => {
    const tee = appContext.getScoreCardTee(scoreCard);
    const teeType = tee?.type || TeeTypeGolf;
    const referencePar = (teeType === TeeTypeGolf ? 72 : 54);
    const scoreCardPar = scoreCard.holes.reduce((prev, hole) => prev + hole.par, 0);
    const factor = referencePar / scoreCardPar;
    return Math.round(+getScoreCardShotCount(scoreCard) * factor);
}

export const scoreCardExtrapolatedStableford = (appContext, scoreCard) => {
    const par = getScoreCardPar(scoreCard);
    return Math.round(scoreCard?.stableford * ((appContext.getScoreCardTee(scoreCard)?.type === TeeTypeGolf ? 72 : 54) / par));
}

export const doubleScoreCardShotCount = (scoreCard1, scoreCard2) => {
    let shots = 0;
    if (scoreCard1 && scoreCard2 && scoreCard1.holes === scoreCard2.holes) {
        for (let i = 0; i < scoreCard1.holes.length; i++) {
            let hole1 = scoreCard1.holes.find(hole => hole.number === i + 1);
            let hole2 = scoreCard2.holes.find(hole => hole.number === i + 1);
            let shotCount1 = getHoleShotCount(hole1);
            let shotCount2 = getHoleShotCount(hole2);
            shots += Math.min(shotCount1, shotCount2);
        }
    }
    return shots;
}

export const doubleScoreCardHCPScore = (scoreCard1, scoreCard2) => {
    let shots = 0;

    if (scoreCard1 && scoreCard2) {
        for (let i = 0; i < scoreCard1.holes.length; i++) {
            let hole1 = scoreCard1.holes.find(hole => hole.number === i + 1);
            let hole2 = scoreCard2.holes.find(hole => hole.number === i + 1);
            let shotCount1 = getHoleHCPScore(hole1);
            let shotCount2 = getHoleHCPScore(hole2);
            shots += Math.min(shotCount1, shotCount2);
        }
    }
    return shots;
}

export const doubleScoreCardScore = (scoreCard1, scoreCard2) => {
    let shots = 0;

    if (scoreCard1 && scoreCard2) {
        for (let i = 0; i < scoreCard1.holes.length; i++) {
            let hole1 = scoreCard1.holes.find(hole => hole.number === i + 1);
            let hole2 = scoreCard2.holes.find(hole => hole.number === i + 1);
            let shotCount1 = getHoleScore(hole1);
            let shotCount2 = getHoleScore(hole2);
            shots += Math.min(shotCount1, shotCount2);
        }
    }
    return shots;
}

export const doubleScoreCardHCPStableford = (scoreCard1, scoreCard2) => {
    let shots = 0;

    if (scoreCard1 && scoreCard2) {
        for (let i = 0; i < scoreCard1.holes.length; i++) {
            let hole1 = scoreCard1.holes.find(hole => hole.number === i + 1);
            let hole2 = scoreCard2.holes.find(hole => hole.number === i + 1);
            let shotCount1 = calculateHoleHCPStableford(hole1);
            let shotCount2 = calculateHoleHCPStableford(hole2);
            shots += Math.max(shotCount1, shotCount2);
        }
    }
    return shots;
}

export const doubleScoreCardStableford = (scoreCard1, scoreCard2) => {
    let shots = 0;

    if (scoreCard1 && scoreCard2) {
        for (let i = 0; i < scoreCard1.holes.length; i++) {
            let hole1 = scoreCard1.holes.find(hole => hole.number === i + 1);
            let hole2 = scoreCard2.holes.find(hole => hole.number === i + 1);
            let stableford1 = calculateHoleStableford(hole1);
            let stableford2 = calculateHoleStableford(hole2);
            shots += Math.max(stableford1, stableford2);
        }
    }
    return shots;
}

// -----------------------------------------------------------------------

export const formatOver = over => {
    return over > 0 ? <span className="text-danger">+{over}</span> : over <= 0 ? <span className="text-success" > {over}</span > : <span>{over}</span>;
}

export const formatFairway = fairway => {
    return <>{getFairwayIcon(fairway === '' ? 0 : fairway)?.icon}</>;
}

export const formatExtraShots = extra => {
    const bar = Array(extra || 0).fill(1);
    return (<>{extra > 0 ? bar.map((_, index) => <BsFillRecordFill size="8" key={index} />) : ""}</>);
    //return (<>{extra > 0 ? <div className="fs-6" style={{ color: 'green' }}>+{extra}</div> : <div>&nbsp;</div>}</>);
}

export const formatDate = (date, showTime = true) => {
    const d = date.toLocaleDateString().split('/');
    const t = date.toLocaleTimeString().substring(0, 5).split(':');
    return d[0].padStart(2, '0') + '/' + d[1].padStart(2, '0') + '/' + d[2].padStart(2, '0') + ' ' +
        (showTime ? t[0].padStart(2, '0') + ':' + t[1].padStart(2, '0') : '');
}

export const formatTimestamp = date => {
    if (date) {
        const d = date.toLocaleDateString().split('/');
        const t = date.toLocaleTimeString().substring(0, 5).split(':');
        return (
            <Stack direction="horizontal" style={{ gap: '8px' }}>
                <span>{d[0].padStart(2, '0') + '/' + d[1].padStart(2, '0') + '/' + d[2].padStart(2, '0')}</span>
                <span>{t[0].padStart(2, '0') + ':' + t[1]}</span>
            </Stack>
        )
    } else {
        return <span>&nbsp;</span>
    }
}

export const getMonthYearOfTimestamp = date => {
    return {
        month: date.getMonth() + 1,
        year: date.getFullYear()
    }
}

export const formatDateToControl = date => {
    const time = (date || new Date())?.toLocaleTimeString().split(':');
    const text = date?.toISOString().substring(0, 10) + 'T' + time[0].padStart(2, '0') + ':' + time[1];
    return text;
}

// -----------------------------------------------------------------------

export const isGIR = hole => {
    return (hole.shotCount - hole.puttCount) <= (hole.par - 2);
}

export const isUpAndDown = hole => {
    return hole.puttCount <= 1 && getHoleScore(hole) > -1 && getHoleShotCount(hole) > 0;
}

export const calculateHoleStablefordByShowHCP = (hole, showHCP) => {
    return showHCP ? calculateHoleHCPStableford(hole) : calculateHoleStableford(hole);
}

export const calculateHoleStableford = hole => {
    if (hole.shotCount === 0) {
        return 0;
    }
    const score = getHoleScore(hole);
    const points = 2 - score;
    return points < 0 ? 0 : points;
}

export const calculateHoleHCPStableford = hole => {
    if (hole.shotCount === 0) {
        return 0;
    }
    const score = getHoleHCPScore(hole);
    const points = 2 - score;
    return points < 0 ? 0 : points;
}

export const calculateHandicapPlay = (appContext, scoreCard, tee, noLimit = true) => {
    if (tee?.type === TeeTypeGolf || tee?.type === TeeTypePAR3) {
        const par = getScoreCardPar(scoreCard);
        const courseAdjust = tee?.cr ? tee?.cr - (tee?.holeCount === 9 ? par * 2 : par) : 0;
        const courseHandicap = scoreCard.handicapGolf * ((+tee?.slope || 113) / 113);
        const handicapPlay = Math.round(courseHandicap + courseAdjust);
        const ret = scoreCard.handicapPlayLimit && noLimit ? Math.min(scoreCard.handicapPlayLimit, handicapPlay) : handicapPlay;
        return ret;
    } else {
        return Math.round(scoreCard.handicapPitchAndPutt)
    }
}

export const calculateHoleStats = (func, holeStart, holeEnd, holes) => {
    const theHoles = holes?.filter(hole => (hole.number >= holeStart) && (hole.number <= holeEnd)) || [];
    return theHoles.reduce((prev, current) => prev + func(current), 0);
}

// -----------------------------------------------------------------------

export const getDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371e3; // metres
    const φ1 = lat1 * Math.PI / 180; // φ, λ in radians
    const φ2 = lat2 * Math.PI / 180;
    const Δφ = (lat2 - lat1) * Math.PI / 180;
    const Δλ = (lon2 - lon1) * Math.PI / 180;

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) * Math.cos(φ2) *
        Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const d = R * c; // in metres
    return d;
}

export const isInsidePolygon = (point, polygon) => {
    // ray-casting algorithm based on
    // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html

    let inside = false;
    if (polygon) {
        let latitude = point.latitude, longitude = point.longitude;

        for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
            let latitudeI = polygon[i].latitude, longitudeI = polygon[i].longitude;
            let latitudeJ = polygon[j].latitude, longitudeJ = polygon[j].longitude;

            let intersect = ((longitudeI > longitude) !== (longitudeJ > longitude))
                && (latitude < (latitudeJ - latitudeI) * (longitude - longitudeI) / (longitudeJ - longitudeI) + latitudeI);
            if (intersect) inside = !inside;
        }
    }
    return inside;
};

// -----------------------------------------------------------------------

export const getScoreCardCalculatedHandicap = (appContext, scoreCard) => {
    const par = getScoreCardPar(scoreCard);
    const shotCount = getScoreCardShotCount(scoreCard);
    return Math.round((shotCount - par) * ((appContext.getScoreCardTee(scoreCard)?.type === TeeTypeGolf ? 72 : 54) / par) * 10) / 10;
}

// -----------------------------------------------------------------------

export const generateTeeId = (tees) => {
    if (!tees) {
        return undefined;
    }
    const values = tees.map(tee => +tee.id || 0);
    const max = values.reduce((prev, value) => Math.max(prev, value), -1);
    return max + 1;
}

export const getCourseTeeFromId = (course, teeId) => {
    return course.tees.find(tee => tee.id === teeId);
}

export const getScoreCardTeeId = (appContext, scoreCard) => {
    if (scoreCard.teeId !== undefined) {
        return scoreCard.teeId;
    }
    const tee = appContext.getScoreCardTee(scoreCard);
    return tee?.id;
}

// -----------------------------------------------------------------------

export const arrayGroup = (array, func) => {
    const result = array.reduce((prev, item) => {
        const key = func(item);
        const group = prev.find(i => i.group === key);
        if (group) {
            group.items.push(item)
        } else {
            prev.push({
                group: key,
                items: [item]
            })
        }
        return prev;
    }, [])
    return result;
}

export const calcularTendencia = (serie) => {
    const n = serie.length;

    // Calcular la suma de los valores x e y
    let sumX = 0;
    let sumY = 0;
    for (let i = 0; i < n; i++) {
        sumX += i + 1; // índice + 1 como valor x
        sumY += serie[i];
    }

    // Calcular la suma de los productos de x e y
    let sumXY = 0;
    for (let i = 0; i < n; i++) {
        sumXY += (i + 1) * serie[i];
    }

    // Calcular la suma de los cuadrados de x
    let sumXSquared = 0;
    for (let i = 0; i < n; i++) {
        sumXSquared += Math.pow(i + 1, 2);
    }

    // Calcular la pendiente (m) y la intersección en y (b) de la línea de regresión
    const m = (n * sumXY - sumX * sumY) / (n * sumXSquared - Math.pow(sumX, 2));
    const b = (sumY / n) - (m * sumX) / n;

    // Devolver la función que calcula la tendencia para un índice dado
    return function (indice) {
        return m * (indice + 1) + b; // índice + 1 como valor x
    };
}

// -----------------------------------------------------------------------

export const clubName = club => club?.name + ' - ' + club?.brand + " " + club?.model + ' - ' + club?.loft + 'º';

// -----------------------------------------------------------------------

